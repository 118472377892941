import {Component, OnInit} from '@angular/core';
import {DmconnectBrowserService} from '../dmconnect-browser.service';
import {HttpParams} from '@angular/common/http';
import {catchError, first, map, retry} from 'rxjs/operators';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {WorkflowService} from '../workflows/workflow-service.service';
import {PluginLocalStorageService} from '../plugin-local-storage.service';

@Component({
  selector: 'app-root',
  template:
  `
    <div class="d-flex mt-4 mx-n4 pb-4 h-100">
      <nav class="col col-lg-3 col-sm-4 col-md-3 bg-secondary sidebar h-100 rounded no-rounded-right">
        <ul class="nav nav-tabs border-0 flex-column my-3 mx-2"><h4>Mappings</h4>
          <li class="nav-item text-nowrap" role="presentation" *ngFor="let val of transposition_categories">
            <button class="nav-link w-100 text-left border-0" id="category-{{val}}" type="button" role="tab" aria-selected="true"
                    (click)="switchCategory(val)" [class.active]="val === selected_category">
              <i class="mdi mdi-file-document-outline"></i>
              <span class="mx-1">{{val}}</span></button>
          </li>
        </ul>
      </nav>
      <div class="col col-lg-9 col-sm-8 col-md-9 mb-3 h-100">
        <div class="card h-100" *ngIf="show_loading">
          <div class="card-body">
            <div class="row mb-3">
              <app-loading-indicator loading_text="Loading transposition values..." [is_loading]="true"></app-loading-indicator>
            </div>
          </div>
        </div>
        <div class="card h-100" *ngIf="!show_loading">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-12 col-md-6 mb-1">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text"><i class="uil-search"></i></div>
                  </div>
                  <input id="searchField" autocomplete="off" type="text" tabindex="1" class="form-control rounded-right"
                         [(ngModel)]="search_phrase"
                         placeholder="Search for a value"/>
                </div>
              </div>
              <div class="col-12 col-md-auto d-inline-flex mb-1">
                <button id="btn-search" class="btn btn-block btn-primary rounded" tabindex="2" type="button" (click)="onClickSearch()"
                        [disabled]="searching">
                  <span *ngIf="searching" class="spinner-border spinner-border-sm me-1" role="status"
                        aria-hidden="true"></span>{{ searching ? "Searching..." : "Search" }}
                </button>
                <button id="btn-clear" class="btn btn-block btn-outline-secondary rounded ms-1" tabindex="4" type="button"
                        (click)="onClickClearSearch()" [disabled]="searching || !results_filtered">
                  <span *ngIf="searching" class="spinner-border spinner-border-sm me-1" role="status"
                        aria-hidden="true"></span>{{ searching ? "Clearing Search..." : "Clear Search" }}
                </button>
              </div>
              <div class="col-12 col-md-auto">
                <button type="submit" class="btn btn-block btn-secondary rounded" tabindex="3" (click)="selectRow(null)"
                        data-bs-toggle="modal" data-bs-target="#modal">Create New Mapping
                </button>
              </div>
            </div>
            <div *ngIf="error_message" class="row mb-2">
              <div class="col-12 mb-3 h-100">
                <div class="alert alert-danger" role="alert">
                  <h4 class="alert-heading">{{error_message}}</h4>
                  <hr>
                  <p class="mb-0">If the error persists please contact us at <a class="text-danger"
                                                                                href="mailto:{{support_email}}">{{support_email}}</a>.</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <table class="table table-hover table-sm table-centered mb-0">
                  <ng-container>
                    <thead>
                    <tr>
                      <th>Left</th>
                      <th>Right</th>
                      <th>Edit</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let val of visible_transpositions | slice: (page - 1) * pageSize : page * pageSize">
                      <td class="text-truncate mxw-10vw">
                        {{val.Left}}
                      </td>
                      <td class="text-truncate mxw-10vw">
                        {{val.Right}}
                      </td>
                      <td class="table-action">
                        <a href="javascript: void(0);" (click)="selectRow(val)" class="action-icon" data-bs-toggle="modal"
                           data-bs-target="#modal"><i class="mdi mdi-pencil"></i></a>
                      </td>
                    </tr>
                    </tbody>
                  </ng-container>
                  <ng-container *ngIf="!this.show_loading && this.visible_transpositions?.length === 0">
                    <tbody>
                    <tr>
                      <td colspan="3">No records found for search phrase. Try broadening your search criteria.</td>
                    </tr>
                    </tbody>
                  </ng-container>
                </table>
                <div class="mt-3 d-flex justify-content-center">
                  <ngb-pagination [disabled]="show_loading" [collectionSize]="visible_transpositions.length" [(page)]="page" [pageSize]="pageSize" class="d-flex justify-content-center"></ngb-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-center mt-2 mb-4">
              <h3>Update Mapping</h3>
            </div>
            <form class="ps-3 pe-3">
              <div class="mb-3">
                <label for="keyValue" class="form-label">Left</label>
                <input class="form-control" type="text" id="keyValue" name="keyValue" autocomplete="false" required="required"
                       placeholder="Left"
                       [(ngModel)]="this.edit_form.key" #key="ngModel"
                       [class.is-invalid]="!this.edit_form.key && key.touched && !validated">
                <div class="invalid-feedback mb-3">
                  Please enter a value.
                </div>
              </div>
              <div class="mb-3">
                <label for="otherId" class="form-label">Right</label>
                <input class="form-control" type="text" id="otherId" name="otherId" autocomplete="false" required="required"
                       placeholder="Right" [(ngModel)]="this.edit_form.value" #value="ngModel"
                       [class.is-invalid]="!this.edit_form.value && value.touched && !validated">
                <div class="invalid-feedback mb-3">
                  Please enter a value.
                </div>
              </div>
              <div class="mb-3 float-end">
                <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                <button class="btn btn-primary mx-1" type="button" (click)="onClickSave()" data-bs-dismiss="modal"
                        [disabled]="this.edit_form.submitting || !this.edit_form.key && !this.edit_form.value">
                  <span *ngIf="this.edit_form.submitting else notLoading">
                      <span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                      Submitting...
                    </span>
                  <ng-template #notLoading>Submit Mapping</ng-template>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .text-left {
      text-align: left;
    }
    .nav-item:hover .nav-link, .nav-link.active {
      color: #ffffff !important;
      background-color: transparent;
    }
    .nav-link, .nav {
      color: #cedce4 !important;
    }
    .no-rounded-right {
     border-top-right-radius: 0 !important;
     border-bottom-right-radius: 0 !important;
    }
    .mxw-10vw {
      max-width: 10vw;
    }
  `]
})
export class TranspositionTopicsComponent implements OnInit {
  public transposition_categories: string[];
  public transposition_holder: any[];
  public visible_transpositions: any[];
  public search_phrase: string = null;

  public selected_category: string;
  public show_loading = true;
  public searching: boolean;
  public results_filtered: boolean;
  public edit_form = new ModalForm();

  public status: string;
  public error_message: string;
  public readonly support_email = 'Support@DarkMatterIns.com';
  public validated: boolean;
  public page = 1;
  public pageSize = 50;

  constructor(private browser: DmconnectBrowserService, private googleAnalytics: GoogleAnalyticsService, private workflowService: WorkflowService, private localStorage: PluginLocalStorageService) {
    this.browser.resize(900,  900);
  }

  ngOnInit(): void {
    const transposition_data = this.localStorage.readTranspositionData() ?? {};

    if (transposition_data) {
      this.selected_category = transposition_data.categories[0];
      this.transposition_categories = transposition_data.categories;
      this.loadTranspositions();
    }
  }

  onClickSearch(): void {
    this.googleAnalytics.event('clicked_search', 'transpositions', 'Clicked Search Button');
    if (this.search_phrase) {
      this.searching = true;
      this.filterRecordsBySearchPhrase();
      this.searching = false;
      this.results_filtered = true;
    }
  }

  onClickClearSearch(): void {
    this.googleAnalytics.event('clicked_clear_search', 'transpositions', 'Clicked Clear Search Button');
    this.search_phrase = null;
    this.searching = true;
    this.filterRecordsBySearchPhrase();
    this.searching = false;
    this.results_filtered = false;
  }

  switchCategory(category_id: string): void {
    this.googleAnalytics.event('clicked_transposition_category', 'transpositions', 'Clicked Transposition Category');
    this.selected_category = category_id;
    this.loadTranspositions();
    this.page = 1;
  }

  onClickSave(): void {
    this.googleAnalytics.event('clicked_save', 'transpositions', 'Clicked Save Changes Button');
    this.edit_form.submitting = true;
    this.edit_form.category = this.selected_category;
    this.workflowService.execute('transposition', this.edit_form)
      .pipe(first(), retry(3), catchError( (response) => { this.error_message = response.output.error.message; return null; })).subscribe((response: any) => {
        if (response.success) {
          this.loadTranspositions();
          this.edit_form.submitting = false;
        } else {
          this.error_message = response.output.error.message;
        }
      });
  }

  public selectRow(obj: any): void {
    this.edit_form.clear();

    if (obj != null) {
      this.edit_form.populate_key_value(obj, this.selected_category);
    }
  }

  private loadTranspositions(): void {
    this.search_phrase = null;
    this.show_loading = true;
    const options = { headers: {'Cache-Control': 'no-cache'}, params: new HttpParams().set('lookup_type', 'transpositions').set('transposition_category', this.selected_category) };
    this.workflowService.execute('lookup', options, 'ams/', 'GET').pipe(first(),
      map(response => {
        const output = response.output;

        if (response.success) {
          this.transposition_holder = output.items;
          this.transposition_holder.sort((a, b) => a.Left < b.Left ? -1 : 1);
          this.visible_transpositions = this.transposition_holder;
          this.error_message = '';
        } else {
          this.transposition_holder = [];
          this.visible_transpositions = [];
          this.error_message = output.error.message;
        }
      })).subscribe(() => this.show_loading = false);
  }

  private filterRecordsBySearchPhrase(): void {
    const output = [];

    if (this.search_phrase == null) {
      this.visible_transpositions = this.transposition_holder;
      return;
    }

    const normalized_search_phrase = this.search_phrase.toUpperCase();
    if (this.transposition_holder != null) {

      for (const record of this.transposition_holder){
        const key = Object.keys(record)[0];
        const normalized_key = key.toUpperCase();
        const normalized_value = record[key].toUpperCase();
        if (normalized_key.includes(normalized_search_phrase) || normalized_value.includes(normalized_search_phrase)) {
          output.push(record);
        }
      }
    }

    this.visible_transpositions = output;
  }
}

class ModalForm {
  public category: string;
  public key: string;
  public value: string;
  public submitting: boolean;
  public old_key: string;
  public old_value: string;

  populate_key_value(obj: any, category: string): void {
    this.key = obj.Left;
    this.old_key = obj.Left;
    this.value = obj.Right;
    this.old_value = obj.Right;
    this.category = category;
  }

  clear(): void {
    this.category = null;
    this.key = null;
    this.value = null;
    this.old_key = null;
    this.old_value = null;
    this.submitting = false;
  }
}
